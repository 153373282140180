
import { Component, Vue, Prop } from 'vue-property-decorator';
import SimplePagination from '@/components/simple-pagination/simple-pagination.vue';
import { IPagination } from '@/entities/pagination/pagination.interface';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import ListSkeleton from '@/modules/shared/components/list-skeleton.component.vue';
import { Unit } from './entities/unit.entity';
import EnterpriseUnitsCard from './components/enterprise-units-card.component.vue';
import EnterpriseUnitsEditDialog from './components/enterprise-units-edit.dialog.component.vue';
import unitsService from './services/units.service';
import EnterpriseUnitsBlockAllDialogComponent from './components/enterprise-units-block-all.dialog.component.vue';

@Component({
  components: {
    EnterpriseUnitsCard,
    DeleteDialog,
    EnterpriseUnitsBlockAllDialogComponent,
    EnterpriseUnitsEditDialog,
    ListSkeleton,
    SimplePagination,
  },
})
export default class EnterpriseUnitsListComponent extends Vue {
  @Prop({ type: Array, default: () => [] })
  public readonly units!: Unit[];

  @Prop({ type: Object, default: () => ({}) })
  public pagination!: IPagination;

  @Prop({ type: String, default: () => '' })
  public readonly enterpriseId!: string;

  @Prop({ type: String, default: () => '' })
  public readonly blockId!: string;

  @Prop({ type: Boolean, default: () => false })
  public readonly loading!: boolean;

  @Prop({ type: String, default: () => '' })
  public readonly companyId!: string;

  public unitsToEdit: Unit[] = [];

  public unitTypes: any[] = [];

  public subgroups: any[] = [];

  public showDialogDelete: boolean = false;

  public itemToDelete: Unit = new Unit();

  public showDialogToEdit: boolean = false;

  public showDialogToEditAllUnits: boolean = false;

  public showDialogToBlockAllUnits: boolean = false;

  public headers: any[] = [
    {
      text: '',
      align: 'start',
      sortable: false,
      value: 'edit',
    },
    {
      text: 'Nome',
      align: 'start',
      sortable: false,
      value: 'name',
    },
    { text: 'Tipo da unidade', sortable: false, value: 'unit_type' },
    { text: 'Subgrupo', sortable: false, value: 'subgroup' },
    { text: 'Valor de venda', sortable: false, value: 'sell_value' },
    { text: 'actions', sortable: false, value: 'actions' },
  ];

  public get isCheckedAll() {
    return this.units.length === this.unitsToEdit.length;
  }

  public setUnitToEdit(unit: Unit) {
    this.unitsToEdit.push(unit);
  }

  public async setUnitForAll(unitTemplate: Unit) {
    this.$emit('set-unit-for-all', unitTemplate);
  }

  public async backPagination() {
    this.$emit('back-pagination');
  }

  public async nextPagination() {
    this.$emit('next-pagination');
  }

  public async goToFirst() {
    this.$emit('go-to-first');
  }

  public setUnitForAllSelected(unitTemplate: Unit) {
    this.unitsToEdit = this.unitsToEdit.map(
      (unit: Unit) => new Unit({
        ...unitTemplate,
        id: unit.id,
        name: unit.name,
        order_number: unit.order_number,
        commercial_stock: unit.commercial_stock,
      }),
    );
    this.$emit('set-unit-for-all-selected', [...this.unitsToEdit]);
  }

  public removeUnitToEdit(unit: Unit) {
    const index = this.unitsToEdit.findIndex((it: Unit) => {
      if (it.temporaryId && it.temporaryId === unit.temporaryId) {
        return true;
      }
      if (it.id && it.id === unit.id) {
        return true;
      }
      return false;
    });
    if (index >= 0) {
      this.unitsToEdit.splice(index, 1);
    }
  }

  public goToFormEdit(unit: Unit) {
    this.$router.push({
      name: 'unit-edit',
      params: {
        enterprise_id: this.enterpriseId,
        id: unit.id,
      },
    });
  }

  public async getUnitTypes() {
    this.unitTypes = await unitsService.getUnitTypes({
      params: {
        company_id: this.companyId,
      },
    });
  }

  public async getSubGroups() {
    this.subgroups = await unitsService.getSubGroups({
      params: {
        company_id: this.companyId,
      },
    });
  }

  public updatePage(page: number) {
    if (page === this.pagination.page) return;
    this.$emit('update-page', page);
  }

  public updateItemsPerPage(perPage: any) {
    if (perPage === this.pagination.limit) return;
    this.$emit('update-options', perPage);
  }

  public setShowDialogToEdit() {
    this.showDialogToEdit = true;
  }

  public setShowDialogToEditAllUnits() {
    this.showDialogToEditAllUnits = true;
  }

  public setShowDialogToBlockAllUnits() {
    this.showDialogToBlockAllUnits = true;
  }

  public deleteItem(unit: Unit) {
    if (this.itemToDelete.temporaryId) {
      this.$emit('remove-temporary-unit', this.itemToDelete);
      this.closeDialogDelete();
    }
  }

  addUnit() {
    this.$emit('add-unit');
  }

  public setShowDialogDelete(unit: Unit) {
    this.itemToDelete = new Unit(unit);
    this.showDialogDelete = true;
  }

  public closeDialogDelete() {
    this.showDialogDelete = false;
  }

  public handleAllUnits(data: boolean) {
    if (data) {
      this.unitsToEdit = [...this.units];
      return;
    }
    this.unitsToEdit = [];
  }

  public created() {
    this.getUnitTypes();
    this.getSubGroups();
  }
}
