
import { Component, Vue, Prop } from 'vue-property-decorator';
import AttachmentDefaultComponent from '@/shared-modules/attachments-default/attachments.component.vue';
import { IAttachmentDefault } from '@/shared-modules/attachments-default/interfaces/attachment-default.interface';

import { Enterprise } from '../../../../entities/enterprise.entity';
import EnterpriseMaterialDialog from './enterprise-material-dialog.component.vue';
import { EnterpriseAttachmentEnum } from '../../enums/enterprise-attachment.enum';
import { EnterpriseAttachmentService } from '../../services/enterprise-attachment.service';

@Component({
  components: {
    AttachmentDefaultComponent,
    EnterpriseMaterialDialog,
  },
})
export default class EnterpriseMaterial extends Vue {
  @Prop({ type: Object, default: () => new Enterprise() })
  private readonly enterprise!: Enterprise;

  private enterpriseAttachmentService = new EnterpriseAttachmentService(
    this.$route.params.id,
    EnterpriseAttachmentEnum.CAMPAIGNS,
  );

  private headers: any = [
    { text: 'Nome', value: 'name', width: '20%' },
    { text: 'Descrição', value: 'description', width: '70%' },
    {
      text: 'Ações',
      align: 'center',
      value: 'actions',
    },
  ];

  private get enterpriseMaterialDialog() {
    return EnterpriseMaterialDialog;
  }

  private get getAttachments() {
    return this.enterpriseAttachmentService.formatAttachments(this.enterprise.attachments);
  }

  private reloadData() {
    this.$emit('reload-data');
  }
}
