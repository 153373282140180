
import { Component, Vue, Prop } from 'vue-property-decorator';
import { IPagination } from '@/entities/pagination/pagination.interface';
import { Unit } from '../entities/unit.entity';
import enterpriseBlocksService from '../../../../services/enterprise-blocks.service';
import unitsService from '../services/units.service';

@Component
export default class EnterpriseUnitsBlockAllDialogComponent extends Vue {
  @Prop({
    type: Boolean,
    default: () => false,
  })
  private readonly value!: boolean;

  @Prop({
    type: String,
    default: () => '',
  })
  private readonly blockId!: string;

  private units: Unit[] = [];

  private selectedUnits: string[] = [];

  private get likesAllUnit() {
    return this.selectedUnits.length === this.units.length;
  }

  private get likesSomeUnit() {
    return this.selectedUnits.length > 0 && !this.likesAllUnit;
  }

  private get icon() {
    if (this.likesAllUnit) return 'mdi-close-box';
    if (this.likesSomeUnit) return 'mdi-minus-box';
    return 'mdi-checkbox-blank-outline';
  }

  private close() {
    this.$emit('input', false);
  }

  private selectAll() {
    if (this.likesAllUnit) {
      this.selectedUnits = [];
      return;
    }
    this.selectedUnits = this.units.map(it => it.id);
  }

  private blockAll() {
    unitsService
      .blockUnits(this.blockId, this.selectedUnits)
      .then(() => {
        this.$snackbar.open({
          text: 'Unidades bloqueadas com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.close();
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao bloquear as unidades',
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(err);
      });
  }

  private async getUnitsAvaiableToBlock() {
    const pagination: IPagination = {
      limit: 2000,
      page: 0,
      total: 1000,
      last_page: 100,
    };

    const { data } = await enterpriseBlocksService.getUnitsByBlockId(
      this.blockId,
      pagination,
      'Disponível',
    );
    this.units = data;
  }

  private created() {
    this.getUnitsAvaiableToBlock();
  }
}
