import http from '@/services/http.service';
import { AxiosResponse } from 'axios';
import { IEnterpriseUnitService, IGetUnitFilter } from '../interface/enterprise-unit.service';
import { IUnitMap } from '../interface/unit-map.interface';

class EnterpriseUnitService implements IEnterpriseUnitService {
  getUnits(params: IGetUnitFilter): Promise<IUnitMap[]> {
    return http
      .get('/unit/basic', {
        params: {
          ...params,
          active: 1,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }
}

export default new EnterpriseUnitService();
