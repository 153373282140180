export enum EnterpriseSituationEnum {
  AVAILABLE = 'Disponível',
  RESERVED = 'Reservado',
  BLOCKED = 'Bloqueado',
  SOLD = 'Vendido',
}

export enum EnterpriseSituationColorEnum {
  AVAILABLE = '#4caf50',
  RESERVED = '#FF5500',
  BLOCKED = '#f44336',
  SOLD = '#757575',
}

export enum EnterpriseSituationTranslatedEnum {
  AVAILABLE = 'Disponível',
  RESERVED = 'Reservada',
  BLOCKED = 'Bloqueada',
  SOLD = 'Vendida',
}
