
import { Component, Vue, Prop } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import DatePicker from '@/components/date-picker/date-picker.component.vue';

import { Address } from '@/components/address/entities/address.entity';
import AddressComponent from '@/components/address/address.component.vue';
import { AddressViaCepInterface } from '@/components/address/interfaces/address-via-cep.interface';
import { IAttachmentDefault } from '@/shared-modules/attachments-default/interfaces/attachment-default.interface';
import handleImageService from '@/modules/shared/services/handle-image.service';
import { PaymentCondition } from '@/modules/Administrative/modules/PaymentConditions/entities/payment-condition.entity';
import paymentConditionService from '@/modules/Administrative/modules/PaymentConditions/services/payment-condition.service';
import { Enterprise } from '../../../../entities/enterprise.entity';
import { Role } from '../../../../../ProfileAccess/entities/role.entity';
import enterpriseService from '../../../../services/enterprise.service';
import { User } from '../../../../../Users/entities/user.entity';
import userService from '../../../../../Users/services/user.service';
import { Block } from '../../entities/block.entity';
import { IBlocksContentForm } from '../../interface/block.interface';
import EnterpriseReadjustUnitValues from '../enterprise-readjust-unit-values/enterprise-readjust-unit-value.component.vue';
import { Unit } from '../enterprise-units/units/entities/unit.entity';
import EnterpriseBannerDialogComponent from './enterprise-banner-dialog.component.vue';
import { EnterpriseAttachmentEnum } from '../../enums/enterprise-attachment.enum';

@Component({
  components: {
    EnterpriseBannerDialogComponent,
    EnterpriseReadjustUnitValues,
    AlertError,
    AddressComponent,
    DatePicker,
  },
})
export default class EnterpriseFormCreateComponent extends Vue {
  @Prop({
    type: Object,
    default: () => new Enterprise(),
  })
  private enterprise!: Enterprise;

  @Prop({
    type: String,
    default: () => '',
  })
  private imageToShow!: string;

  public $refs: any;

  private qtyBlocks: number = 0;

  private blocks: IBlocksContentForm[] = [];

  private status: any[] = [
    {
      id: 0,
      name: 'Inativo',
    },
    {
      id: 1,
      name: 'Ativo',
    },
  ];

  private showDialogBanner: boolean = false;

  private users: User[] = [];

  private paymentConditions: PaymentCondition[] = [];

  private errors: any[] = [];

  private companies: any[] = [];

  private rules: any = {
    required: rulesService.required,
    email: rulesService.email,
    greaterThanOrEqualOne: this.greaterThanOrEqualOne,
  };

  private metersMask = {
    decimal: ',',
    thousands: '.',
    prefix: '',
    suffix: ' m²',
    precision: '',
    masked: false,
  };

  private get allUnitsValue(): number {
    return this.blocks.reduce((acc: number, value: IBlocksContentForm) => {
      if (value.quantity) {
        acc += Number(value.quantity);
      }
      return acc;
    }, 0);
  }

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private get labelFormType() {
    return this.isEdit ? 'Editar' : 'Cadastrar';
  }

  public greaterThanOrEqualOne(value: string) {
    if (!value) {
      return true;
    }
    return Number(value) >= 1 ? true : 'O valor deve ser maior ou igual a 1';
  }

  private setQtyBlocks(value: number) {
    if (value < 1) {
      return;
    }
    this.enterprise.blocks = new Block(value);
    this.blocks = this.enterprise.blocks.generateBlocks();
    this.enterprise.blocks.setContentBlocks(this.blocks);
  }

  private async addAttachment(attachment: IAttachmentDefault) {
    if (attachment.file && attachment.file.length) {
      const imageToShow = await handleImageService.convertFileToBase64(attachment.file[0]);
      this.$emit('set-image-to-show', imageToShow);
      this.enterprise.setBanner(attachment).then(() => {
        this.$snackbar.open({
          text: 'Imagem cadastrada com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
      });
    }
    this.showDialogBanner = false;
  }

  private setUnitForAll(unitsToEdit: Unit[] = []) {
    this.enterprise.units = this.enterprise.units.map((unit: Unit) => {
      const unitToEdit: any = unitsToEdit.find(it => it.id === unit.id);
      if (unitToEdit) {
        return unitToEdit;
      }
      return unit;
    });
  }

  private get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  private get showAddressComponent() {
    if (!this.isEdit) {
      return true;
    }
    return !!this.enterprise.address.cep;
  }

  private setShowOpenDialogBanner() {
    this.showDialogBanner = true;
  }

  private async getAllUsers() {
    if (!this.enterprise.company_id) return;
    const users = await userService.getAllUsers({
      params: {
        company_id: this.enterprise.company_id,
      },
    });
    this.users = [];
    this.users = users.map((it: any) => new User(it));
  }

  private async getAllPaymentConditions() {
    if (!this.enterprise.company_id) return;
    const paymentConditions = await paymentConditionService.getAllPayments({
      params: {
        company_id: this.enterprise.company_id,
        active: 1,
      },
    });
    this.paymentConditions = [];
    this.paymentConditions = paymentConditions.map((it: any) => new PaymentCondition(it));
  }

  private async getCompanies() {
    const companies = await enterpriseService.getCompanies();
    this.companies = companies;
    if (this.companies.length > 0 && !this.enterprise.company_id) {
      this.enterprise.company_id = this.companies[0].id;
      this.getAllUsers();
      this.getAllPaymentConditions();
    }
  }

  private async setAddressByCep(address: AddressViaCepInterface) {
    const fullAddress = { ...address, number: this.enterprise.address.number };
    this.enterprise.address = new Address(fullAddress);
  }

  private validate() {
    return this.$refs.form.validate();
  }

  private goToEdit(id: string) {
    this.$router.push({
      name: 'enterprise-edit',
      params: {
        id,
      },
    });
  }

  private setCompanyId(companyId: string) {
    this.$emit('set-enterprise-id', companyId);
    this.getAllUsers();
    this.getAllPaymentConditions();
  }

  private async goToList() {
    this.$router.push({
      name: 'enterprise-list',
    });
  }

  private async created() {
    await this.getCompanies();
    await this.getAllUsers();
    await this.getAllPaymentConditions();
    this.blocks = this.enterprise.blocks.generateBlocks();
  }
}
