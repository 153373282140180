
import { Component, Vue, Prop } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import UploadComponent from '@/components/upload/upload.component.vue';
import { Utils } from '@/services/utils/utils.service';
import AttachmentDefault from '@/shared-modules/attachments-default/entities/attachment-default.entity';
import { IAttachmentDefault } from '@/shared-modules/attachments-default/interfaces/attachment-default.interface';

@Component({
  components: {
    UploadComponent,
  },
})
export default class EnterpriseMaterialDialogComponent extends Vue {
  public $refs: any;

  @Prop({ type: Boolean, default: () => false })
  private readonly value!: boolean;

  private attachment: IAttachmentDefault = new AttachmentDefault({ id: Utils.generateID() });

  private rules: any = {
    required: rulesService.required,
  };

  private closeDialog(data: boolean = false) {
    if (data === true) return;
    this.$emit('input', false);
  }

  private save() {
    if (!this.$refs.form.validate()) return;
    this.$emit('save', this.attachment);
  }
}
