import http from '@/services/http.service';
import { AxiosResponse } from 'axios';

import { IEnterpriseCommercialStocksService } from '../interface/enterprise-commercial-stocks-service.interface';

class EnterpriseCommercialStocksService implements IEnterpriseCommercialStocksService {
  getCommercialStocks(enterprise_id: string): Promise<string[]> {
    return http
      .get('/unit/commercial-stocks-all', {
        params: {
          enterprise_id,
        },
      })
      .then(({ data }: AxiosResponse) => data.data);
  }
}

export default new EnterpriseCommercialStocksService();
