import { IGetUnitFilter } from '../interface/enterprise-unit.service';

export class UnitMapFilterEntity implements IGetUnitFilter {
  public blocks: string[];

  public commercial_stocks: string[];

  public enterprise_id: string;

  constructor(
    data: IGetUnitFilter = {
      enterprise_id: '',
      blocks: [],
      commercial_stocks: [],
    },
  ) {
    this.blocks = data.blocks || [];
    this.commercial_stocks = data.commercial_stocks || [];
    this.enterprise_id = data.enterprise_id || '';
  }
}
