
import { Component, Vue, Prop } from 'vue-property-decorator';

import downloadService from '@/services/download.service';
import { IAttachmentDefault } from './interfaces/attachment-default.interface';
import { IAttachmentDefaultService } from './interfaces/abstract-attachment-default.service';
import AttachmentDialog from './attachments.dialog.component.vue';

@Component({
  components: {
    AttachmentDialog,
  },
})
export default class AttachmentDefaultComponent extends Vue {
  @Prop({ type: Array, default: () => [] })
  private readonly attachments!: IAttachmentDefault;

  @Prop({ type: Object, required: true })
  private readonly attachmentService!: IAttachmentDefaultService;

  @Prop({ type: String, default: () => 'Enviar anexo' })
  private readonly title!: string;

  @Prop({ type: Boolean, default: () => false })
  private readonly hideActions!: boolean;

  @Prop({ type: Function, default: () => AttachmentDialog })
  private readonly componentDialog!: Vue.Component;

  @Prop({
    type: Array,
    default: () => [
      { text: 'Nome', value: 'name', width: '90%' },
      {
        text: 'Ações',
        align: 'center',
        value: 'actions',
      },
    ],
  })
  private readonly headers!: any[];

  private showDialog: boolean = false;

  private openDialog() {
    this.showDialog = true;
  }

  private closeDialog() {
    this.showDialog = false;
  }

  private getFormDataFile(attachment: IAttachmentDefault) {
    const formData = new FormData();
    if (attachment.file && attachment.file.length) {
      formData.append('file', attachment.file[0]);
    }
    return formData;
  }

  private async save(attachment: IAttachmentDefault) {
    this.$loader.open();
    await this.attachmentService
      .send(attachment)
      .then(() => {
        this.$snackbar.open({
          text: 'Anexo adicionado com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.$emit('change');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao adicionar o anexo',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.closeDialog();
        this.$loader.close();
      });
  }

  private openAttachment(attachment: IAttachmentDefault) {
    if (attachment.path) {
      downloadService.openInNewTab(attachment.path);
      return;
    }
    downloadService.downloadFile(attachment);
  }

  private async deleteAttachmment(attachment: IAttachmentDefault) {
    this.$loader.open();
    await this.attachmentService
      .remove(attachment.id)
      .then(() => {
        this.$snackbar.open({
          text: 'Anexo removido com sucesso',
          buttonColor: 'white',
          color: 'success',
        });
        this.$emit('change');
      })
      .catch(() => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao remover o anexo',
          buttonColor: 'white',
          color: 'danger',
        });
      })
      .finally(() => {
        this.closeDialog();
        this.$loader.close();
      });
  }
}
