
import { Component, Vue, Prop } from 'vue-property-decorator';
import AttachmentDefaultComponent from '@/shared-modules/attachments-default/attachments.component.vue';
import { IAttachmentDefault } from '@/shared-modules/attachments-default/interfaces/attachment-default.interface';

import { Utils } from '@/services/utils/utils.service';
import { IPagination } from '@/entities/pagination/pagination.interface';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import { Pagination } from '@/entities/pagination/pagination.entity';
import { Enterprise } from '../../../../entities/enterprise.entity';
import { EnterpriseAttachmentEnum } from '../../enums/enterprise-attachment.enum';
import { EnterpriseAttachmentService } from '../../services/enterprise-attachment.service';
import EnterpriseUnitsListComponent from './units/enterprise-units-list.component.vue';
import enterpriseBlocksService from '../../services/enterprise-blocks.service';
import { IEnterpriseBlocks } from '../../interface/enterprise-blocks.interface';
import enterpriseUnitService from '../../services/enterprise-unit.service';
import { CommercialStocksEnum } from './units/enums/commercial-stocks.enum';
import { Unit } from './units/entities/unit.entity';
import unitsService from './units/services/units.service';
import { IEditAllUnitsPayload } from './units/services/edit-all-units-payload.interface';

@Component({
  components: {
    AlertError,
    AttachmentDefaultComponent,
    EnterpriseUnitsListComponent,
  },
})
export default class EnterpriseUnits extends Vue {
  @Prop({ type: Object, default: () => new Enterprise() })
  private readonly enterprise!: Enterprise;

  private blocks: IEnterpriseBlocks[] = [];

  private currentBlockSelected: IEnterpriseBlocks[] = [];

  private blockIdSelected: string = '';

  private errors: any[] = [];

  private quantityUnits: number = 0;

  private paginationFilter: IPagination = new Pagination({
    limit: 10,
    page: 1,
    total: 10,
    last_page: 2,
  });

  private loading: boolean = false;

  private messageUnitsInQueue: boolean = false;

  private units: Unit[] = [];

  private unitsToEdit: Unit[] = [];

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  public addUnit() {
    const unit = new Unit({
      temporaryId: Utils.generateID(),
      total_units: this.quantityUnits,
    });
    this.quantityUnits = this.quantityUnits + 1;
    this.units.push(unit);
    this.unitsToEdit.push(unit);
  }

  async removeTemporaryUnit(unit: Unit) {
    const index = this.units.findIndex(it => it.temporaryId === unit.temporaryId);
    this.units.splice(index, 1);
    this.$snackbar.open({
      text: 'Unidade excluida com sucesso',
      color: 'success',
      buttonColor: '#fff',
    });
  }

  public setUnitForAll(unit: Unit) {
    const unitTemplate = unit.format();
    const payload: IEditAllUnitsPayload = {
      block_id: this.blockIdSelected,
      unit: { ...unitTemplate, name: undefined },
    };
    this.errors = [];
    unitsService
      .editAllUnits(payload)
      .then(() => {
        this.setShowmessageUnitsInQueue();
        this.errors = [];
        this.$snackbar.open({
          text: 'Alterações feitas com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.getUnitsByBlockId(this.blockIdSelected);
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao salvar a edição das unidades',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = err.response.data.errors;
        this.scrollTop();
      });
  }

  private scrollTop() {
    window.scroll({ top: 0, behavior: 'smooth' });
  }

  public setUnitForAllSelected(units: Unit[]) {
    const payload = units.map((it: Unit) => ({
      ...it.format(),
      block_id: this.blockIdSelected,
      enterprise_id: this.enterprise.id,
    }));
    return unitsService
      .saveUnitsInLots(payload, this.blockIdSelected)
      .then(() => {
        this.setShowmessageUnitsInQueue();

        this.$snackbar.open({
          text: 'Alterações feitas com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$emit('reload-data');
        this.getUnitsByBlockId(this.blockIdSelected);
        this.unitsToEdit = [];
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao salvar a edição das unidades',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = err.response.data.errors;
        this.scrollTop();
      });
  }

  private async updatePage(page: any) {
    this.loading = true;
    this.paginationFilter.page = page;
    await this.getUnitsByBlockId(this.blockIdSelected);
    this.loading = false;
  }

  private setShowmessageUnitsInQueue() {
    this.messageUnitsInQueue = true;
    setTimeout(() => {
      this.messageUnitsInQueue = false;
    }, 60000);
  }

  private async getBlocks() {
    this.blocks = await enterpriseBlocksService.getBlocks(this.enterprise.id);
    if (this.blocks.length) {
      this.blockIdSelected = this.$route.params.blockId
        ? this.$route.params.blockId
        : this.blocks[0].id;
      await this.getUnitsByBlockId(this.blockIdSelected);
    }
  }

  private async getUnitsByBlockId(blockId: string) {
    this.loading = true;
    this.units = [];
    const response = await enterpriseBlocksService.getUnitsByBlockIdPaginated(blockId);

    this.$nextTick(() => {
      this.units = response.length ? response.map((it: any) => new Unit(it)) : [];
      this.$nextTick(() => {
        this.loading = false;
      });
    });
  }

  async backPagination() {
    if (!enterpriseBlocksService.simplePaginationService.prevLink) return;
    const response = await enterpriseBlocksService.simplePaginationService.prev();
    this.$nextTick(() => {
      this.units = response.length ? response.map((it: any) => new Unit(it)) : [];
      this.$nextTick(() => {
        this.loading = false;
      });
    });
  }

  async nextPagination() {
    if (!enterpriseBlocksService.simplePaginationService.nextLink) return;
    const response = await enterpriseBlocksService.simplePaginationService.next();
    this.$nextTick(() => {
      this.units = response.length ? response.map((it: any) => new Unit(it)) : [];
      this.$nextTick(() => {
        this.loading = false;
      });
    });
  }

  async goToFirst() {
    if (!enterpriseBlocksService.simplePaginationService.firstLink) return;
    const response = await enterpriseBlocksService.simplePaginationService.goToFirst();
    this.$nextTick(() => {
      this.units = response.length ? response.map((it: any) => new Unit(it)) : [];
      this.$nextTick(() => {
        this.loading = false;
      });
    });
  }

  getUnitsToSave() {
    return this.units.map((it: Unit) => {
      const unitToEdited = this.unitsToEdit.find((unit: Unit) => {
        if (it.id === unit.id) {
          return true;
        }
        if (!it.id && it.temporaryId === unit.temporaryId) {
          return true;
        }
        return false;
      });

      if (unitToEdited) {
        this.units.splice(1, 1, unitToEdited);
      }
      return it;
    });
  }

  private saveUnits() {
    const units = this.units.map((it: Unit) => ({
      id: it.id,
      name: it.name,
      unit_type_id: it.unit_type_id,
      sale_value: it.sale_value,
      subgroups: it.subgroups.map((subgroup: any) => subgroup.id),
    }));

    this.errors = [];
    unitsService
      .saveUnitsTable(units, this.blockIdSelected)
      .then(() => {
        this.$snackbar.open({
          text: 'Alterações feitas com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$emit('reload-data');
        this.getUnitsByBlockId(this.blockIdSelected);
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao salvar a edição das unidades',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = err.response.data.errors;
        this.scrollTop();
      });
  }

  private async created() {
    this.$loader.open();
    await this.getBlocks();
    this.quantityUnits = this.paginationFilter.total;
    this.$loader.close();
  }
}
