
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Unit } from '../entities/unit.entity';
import unitsService from '../services/units.service';
import UnitFormComponent from './unit-form.component.vue';

@Component({
  components: {
    UnitFormComponent,
  },
})
export default class EnterpriseUnitsEditDialogComponent extends Vue {
  public $refs: any;

  @Prop({ type: Boolean, default: () => false })
  private readonly value!: boolean;

  @Prop({ type: Number, default: () => 0 })
  private readonly numberOfUnits!: number;

  @Prop({ type: String, default: () => '' })
  private readonly companyId!: string;

  private unit = new Unit({
    company: {
      id: this.companyId,
    },
  });

  private unitTypes: any[] = [];

  private subgroups: any[] = [];

  private moneyOptionsMask = {
    locale: 'pt-BR',
    prefix: 'R$',
    suffix: '',
    length: 9,
    precision: 2,
  };

  private closeDialog(data: boolean = false) {
    if (data === true) return;
    this.$emit('input', false);
  }

  private async getUnitTypes() {
    this.unitTypes = await unitsService.getUnitTypes({
      params: {
        company_id: this.companyId,
      },
    });
  }

  private async getSubGroups() {
    this.subgroups = await unitsService.getSubGroups({
      params: {
        company_id: this.companyId,
      },
    });
  }

  private saveUnit() {
    if (!this.$refs.form.validate()) return;
    this.$emit('set-unit-for-all', this.unit);

    this.unit = new Unit();
    this.closeDialog();
  }

  private created() {
    this.getUnitTypes();
    this.getSubGroups();
  }
}
