
import { Component, Vue, Prop } from 'vue-property-decorator';
import AttachmentDefaultComponent from '@/shared-modules/attachments-default/attachments.component.vue';
import { IAttachmentDefault } from '@/shared-modules/attachments-default/interfaces/attachment-default.interface';
import AttachmentsDialog from '@/shared-modules/attachments-default/attachments.dialog.component.vue';

import { Enterprise } from '../../../../entities/enterprise.entity';
import { EnterpriseAttachmentEnum } from '../../enums/enterprise-attachment.enum';
import { EnterpriseAttachmentService } from '../../services/enterprise-attachment.service';

@Component({
  components: {
    AttachmentDefaultComponent,
  },
})
export default class EnterpriseMaterial extends Vue {
  @Prop({ type: Object, default: () => new Enterprise() })
  private readonly enterprise!: Enterprise;

  private enterpriseAttachmentService = new EnterpriseAttachmentService(
    this.$route.params.id,
    EnterpriseAttachmentEnum.PLANTS,
  );

  private headers: any = [
    { text: 'Nome', value: 'name', width: '90%' },
    {
      text: 'Ações',
      align: 'center',
      value: 'actions',
    },
  ];

  private get getAttachments() {
    return this.enterpriseAttachmentService.formatAttachments(this.enterprise.attachments);
  }

  private get attachmentsDialog() {
    return AttachmentsDialog;
  }

  private reloadData() {
    this.$emit('reload-data');
  }
}
