
import { Component, Vue, Watch } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import AlertError from '@/components/alert-error/alert-error.component.vue';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';
import { Enterprise } from '../../entities/enterprise.entity';
import { Role } from '../../../ProfileAccess/entities/role.entity';
import enterpriseService from '../../services/enterprise.service';
import { Address } from '../../../../../../components/address/entities/address.entity';

import { User } from '../../../Users/entities/user.entity';
import userService from '../../../Users/services/user.service';
import EnterpriseFormCreate from './components/enterprise-form/enterprise-form.component.vue';
import EnterpriseMap from './components/enterprise-map/enterprise-map.component.vue';
import EnterpriseResume from './components/enterprise-resume/enterprise-resume.component.vue';
import EnterpriseAttachments from './components/enterprise-attachments/enterprise-attachments.component.vue';
import EnterpriseMaterial from './components/enterprise-material/enterprise-material.component.vue';
import EnterprisePlants from './components/enterprise-plants/enterprise-plants.component.vue';
import EnterpriseUnits from './components/enterprise-units/enterprise-units.component.vue';
import { EnterpriseAttachmentEnum } from './enums/enterprise-attachment.enum';

@Component({
  components: {
    AlertError,
    DeleteDialog,
    EnterpriseFormCreate,
    EnterpriseMap,
    EnterpriseResume,
    EnterpriseAttachments,
    EnterpriseMaterial,
    EnterprisePlants,
    EnterpriseUnits,
  },
})
export default class EnterpriseWrapperComponent extends Vue {
  public $refs: any;

  private enterprise: Enterprise = new Enterprise();

  private tab: number = 0;

  private showDialogDelete: boolean = false;

  private imageToShow: string = '';

  private errors: any[] = [];

  private get items() {
    return [
      {
        id: 'cadastro',
        title: 'Cadastro',
        component: EnterpriseFormCreate,
        disabled: false,
      },
      {
        id: 'resumo',
        title: 'Resumo',
        component: EnterpriseResume,
        disabled:
          !this.enterprise.id
          || this.$permissionAccess.isBroker
          || this.$permissionAccess.isSalesManager,
      },
      {
        id: 'unidades',
        title: 'Unidades',
        component: EnterpriseUnits,
        disabled:
          !this.enterprise.id
          || this.$permissionAccess.isBroker
          || this.$permissionAccess.isSalesManager,
      },
      {
        id: 'mapa',
        title: 'Mapa',
        component: EnterpriseMap,
        disabled: !this.enterprise.id,
      },
      {
        id: 'anexos',
        title: 'Anexos',
        component: EnterpriseAttachments,
        disabled: !this.enterprise.id,
      },
      {
        id: 'material-de-campanha',
        title: 'Material de campanha',
        component: EnterpriseMaterial,
        disabled: !this.enterprise.id,
      },
      {
        id: 'plantas',
        title: 'Plantas',
        component: EnterprisePlants,
        disabled: !this.enterprise.id,
      },
    ];
  }

  private rules: any = {
    required: rulesService.required,
    email: rulesService.email,
  };

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private get labelFormType() {
    return this.isEdit ? 'Editar' : 'Cadastrar';
  }

  private get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  private async getEnterpriseById(id: string) {
    this.$loader.open();
    const enterprise = await enterpriseService.getEnterpriseById(id).finally(() => {
      this.$loader.close();
    });
    this.enterprise = new Enterprise(enterprise);
    this.getImageToShow();
  }

  private getImageToShow() {
    const file = this.enterprise.attachments.find((it: any) => EnterpriseAttachmentEnum.PRINCIPAL);
    if (file) {
      this.imageToShow = file.path;
    }
  }

  private setEnterpriseId(companyId: string) {
    this.enterprise = new Enterprise();
    this.enterprise.company_id = companyId;
  }

  private setShowDialogDelete() {
    this.showDialogDelete = true;
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private validateAllForms() {
    return this.$refs.form.every((it: any) => (it.validate ? it.validate() : true));
  }

  private async submit() {
    if (!this.validateAllForms()) return;
    if (this.isEdit) {
      this.edit();
      return;
    }
    this.save();
  }

  private goToEdit(id: string) {
    this.$router.push({
      name: 'enterprise-edit',
      params: {
        id,
      },
    });
  }

  private deleteItem() {
    this.enterprise
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'Empreedimento apagado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.closeDialogDelete();
        this.$router.push({
          name: 'enterprise-list',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao apagar o empreedimento',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.closeDialogDelete();
      });
  }

  private async save() {
    this.$loader.open();
    this.enterprise
      .save()
      .then((data: any) => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Empreedimento cadastrado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToEdit(data.id);
        this.getEnterpriseById(data.id);
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao cadastrar empreedimento',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = err.response.data.errors;
      })
      .finally(() => {
        this.$loader.close();
      });
  }

  private async edit() {
    this.$loader.open();
    this.enterprise
      .update()
      .then((data: any) => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Empreendimento alterado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.getEnterpriseById(data.id);
      })
      .catch((err: any) => {
        this.errors = err.response.data.errors;
      })
      .finally(() => {
        this.$loader.close();
      });
  }

  private async reloadData() {
    const { id } = this.$route.params;
    await this.getEnterpriseById(id);
  }

  private setImageToShow(image: string) {
    this.imageToShow = image;
  }

  private onChangeTab(item: any) {
    const tab = this.items[item];
    this.$router.push({
      name: this.$route.name || '',
      params: {
        tab: tab.id,
      },
    });
  }

  private async goToList() {
    this.$router.push({
      name: 'enterprise-list',
    });
  }

  @Watch('$route')
  private goToTab() {
    const { tab } = this.$route.params;
    if (tab) {
      const index = this.items.findIndex((it: any) => it.id === tab);
      this.tab = index >= 0 ? index : 0;
    }
  }

  private async created() {
    if (this.isEdit) {
      const { id } = this.$route.params;
      await this.getEnterpriseById(id);
      await this.goToTab();
    }
  }
}
