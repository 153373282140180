
import { Component, Vue, Prop } from 'vue-property-decorator';
import Chart from 'chart.js';
import rulesService from '@/services/rules.service';
import readjustService from '../../services/enterprise-readjust-unit-values.service';
import { Enterprise } from '../../../../entities/enterprise.entity';
import { EnterpriseTypesToCalcEnum } from './enums/enterprise-types-to-calc.enum';
import { EnterpriseOperatorsToCalcEnum } from './enums/enterprise-operators-to-calc.enum';

@Component
export default class EnterpriseReadjustUnitValues extends Vue {
  public $refs: any;

  @Prop({
    type: Object,
    default: () => new Enterprise(),
  })
  private enterprise!: Enterprise;

  private value: any = 0;

  private percent_value: any = 0;

  private operator: EnterpriseOperatorsToCalcEnum = EnterpriseOperatorsToCalcEnum.INCREASE;

  private typeCalc: EnterpriseTypesToCalcEnum = EnterpriseTypesToCalcEnum.PERCENT;

  private moneyOptionsMask = {
    locale: 'pt-BR',
    prefix: 'R$',
    suffix: '',
    length: 9,
    precision: 2,
  };

  private operators: any[] = [
    {
      id: EnterpriseOperatorsToCalcEnum.INCREASE,
      name: 'Acrescentar',
    },
    {
      id: EnterpriseOperatorsToCalcEnum.DECREASE,
      name: 'Decrementar',
    },
  ];

  private typesCalc: any[] = [
    {
      id: this.percentEnum,
      name: 'Percentual',
    },
    {
      id: this.currencyEnum,
      name: 'Valor Monetário',
    },
  ];

  private errors: any[] = [];

  private rules: any = {
    required: rulesService.required,
  };

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private async submit() {
    if (!this.$refs.form.validate()) return;
    this.execute();
  }

  private get percentEnum() {
    return EnterpriseTypesToCalcEnum.PERCENT;
  }

  private get currencyEnum() {
    return EnterpriseTypesToCalcEnum.CURRENCY;
  }

  private async execute() {
    readjustService
      .readjust(this.enterprise.id, {
        type: this.typeCalc,
        operator: this.operator,
        value: this.value,
      })
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Valor das unidades ajustados com sucesso!',
          color: 'success',
          buttonColor: '#fff',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao reajustar valor das unidades',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = err.response.data.errors;
      });
  }
}
