
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Enterprise } from '../../../../entities/enterprise.entity';

@Component
export default class EnterpriseChartConversion extends Vue {
  public $refs: any;

  @Prop({
    type: Object,
    default: () => new Enterprise(),
  })
  private enterprise!: Enterprise;
}
