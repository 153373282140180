
import { Component, Vue, Prop } from 'vue-property-decorator';
import AttachmentDefaultComponent from '@/shared-modules/attachments-default/attachments.component.vue';
import AttachmentsDialog from '@/shared-modules/attachments-default/attachments.dialog.component.vue';

import { Enterprise } from '../../../../entities/enterprise.entity';
import { EnterpriseAttachmentEnum } from '../../enums/enterprise-attachment.enum';
import { EnterpriseAttachmentService } from '../../services/enterprise-attachment.service';

@Component({
  components: {
    AttachmentDefaultComponent,
  },
})
export default class EnterpriseAttachments extends Vue {
  @Prop({ type: Object, default: () => new Enterprise() })
  private readonly enterprise!: Enterprise;

  private enterpriseAttachmentService = new EnterpriseAttachmentService(
    this.$route.params.id,
    EnterpriseAttachmentEnum.GENERAL,
  );

  private get getAttachments() {
    return this.enterpriseAttachmentService.formatAttachments(this.enterprise.attachments);
  }

  private get attachmentsDialog() {
    return AttachmentsDialog;
  }

  private reloadData() {
    this.$emit('reload-data');
  }
}
