
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Unit } from '../entities/unit.entity';
import unitsService from '../services/units.service';

@Component
export default class EnterpriseUnitsCard extends Vue {
  @Prop({ type: Object, default: () => new Unit() })
  private readonly unit!: Unit;

  @Prop({ type: Array, default: () => [] })
  private readonly unitTypes!: any[];

  @Prop({ type: Array, default: () => [] })
  private readonly subgroups!: any[];

  @Prop({ type: Array, default: () => [] })
  private readonly unitsToEdit!: Unit[];

  private moneyOptionsMask = {
    locale: 'pt-BR',
    prefix: 'R$',
    suffix: '',
    length: 9,
    precision: 2,
  };

  private get isSelected() {
    return this.unitsToEdit.some((unitToEdit: Unit) => {
      if (this.unit.temporaryId && this.unit.temporaryId === unitToEdit.temporaryId) {
        return true;
      }
      if (unitToEdit.id && unitToEdit.id === this.unit.id) {
        return true;
      }
      return false;
    });
  }

  private goToFormEdit() {
    this.$emit('go-to-form-edit', this.unit);
  }

  private setShowDialogDelete() {
    this.$emit('set-show-dialog-delete', this.unit);
  }

  private handleItemToEdit(check: boolean) {
    if (check === true) {
      this.setUnitToEdit(this.unit);
      return;
    }
    this.removeUnitToEdit(this.unit);
  }

  private setUnitToEdit(unit: Unit) {
    this.$emit('set-unit-to-edit', unit);
  }

  private removeUnitToEdit(unit: Unit) {
    this.$emit('remove-unit-to-edit', unit);
  }
}
