
import { Component, Vue } from 'vue-property-decorator';
import {
  EnterpriseSituationEnum,
  EnterpriseSituationColorEnum,
  EnterpriseSituationTranslatedEnum,
} from '../../../enums/enterprise-situation.enum';
import enterpriseUnitService from '../../services/enterprise-unit.service';
import { IGetUnitFilter } from '../../interface/enterprise-unit.service';
import { UnitMapFilterEntity } from '../../entities/unit-map-filter.entity';
import { IUnitMap } from '../../interface/unit-map.interface';
import { IEnterpriseBlocks } from '../../interface/enterprise-blocks.interface';
import enterpriseBlocksService from '../../services/enterprise-blocks.service';
import enterpriseCommercialStocksService from '../../services/enterprise-commercial-stocks.service';
import { Unit } from '../enterprise-units/units/entities/unit.entity';

@Component
export default class EnterpriseMap extends Vue {
  public $htmlToPaper: any;

  private unitsByBlocks: IUnitMap[] = [];

  private blocks: IEnterpriseBlocks[] = [];

  private situations: string[] = [];

  private mapFilter: IGetUnitFilter = new UnitMapFilterEntity({
    enterprise_id: this.$route.params.id,
    blocks: [],
    commercial_stocks: [],
  });

  private getSituationKey(situation: string) {
    const allSituations: any = EnterpriseSituationEnum;
    return Object.keys(allSituations).find((it: any) => allSituations[it] === situation);
  }

  private color(situation: string) {
    const situationKey: any = this.getSituationKey(situation);
    const colors: any = EnterpriseSituationColorEnum;
    return situationKey ? colors[situationKey] : EnterpriseSituationColorEnum.AVAILABLE;
  }

  private print() {
    // Pass the element id here
    this.$htmlToPaper('print');
  }

  private goToUnit(id: string) {
    this.$router.push({
      name: 'unit-edit',
      params: {
        id,
        enterprise_id: this.$route.params.id,
      },
    });
  }

  private goToOrder(id: string) {
    this.$router.push({
      name: 'client-edit',
      params: {
        id,
      },
    });
  }

  private get situationLabels() {
    const situations: any = EnterpriseSituationEnum;
    const labels: any = EnterpriseSituationTranslatedEnum;
    const colors: any = EnterpriseSituationColorEnum;
    return Object.keys(situations).reduce((acc: any[], values) => {
      const description: string = labels[values];
      const color: string = colors[values];

      acc.push({ description, color });
      return acc;
    }, []);
  }

  private formatUnitPerBlock(data: IUnitMap[]) {
    return data
      .reduce((acc: any, value: IUnitMap) => {
        const index = acc.findIndex((it: any) => it.blockId === value.block.id);

        if (index >= 0) {
          acc[index].items.push(value);
          return acc;
        }

        acc.push({ blockId: value.block.id, blockName: value.block.name, items: [value] });
        return acc;
      }, [])
      .sort((a: any, b: any) => (a.blockName > b.blockName ? 1 : -1));
  }

  private async getUnitsToMap() {
    await enterpriseUnitService
      .getUnits(this.mapFilter)
      .then((data: IUnitMap[]) => {
        this.unitsByBlocks = this.formatUnitPerBlock(data);
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao buscar os dados.',
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(`${err}`);
      });
  }

  private async getEnterpriseBlocks() {
    await enterpriseBlocksService
      .getBlocks(this.$route.params.id)
      .then((data: IEnterpriseBlocks[]) => {
        this.blocks = data;
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao buscar os dados.',
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(`${err}`);
      });
  }

  private async getCommercialStocks() {
    await enterpriseCommercialStocksService
      .getCommercialStocks(this.$route.params.id)
      .then((data: string[]) => {
        this.situations = data;
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Ocorreu um erro ao buscar os dados.',
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(`${err}`);
      });
  }

  private created() {
    this.getUnitsToMap();
    this.getEnterpriseBlocks();
    this.getCommercialStocks();
  }
}
