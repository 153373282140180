
import { Component, Vue, Prop } from 'vue-property-decorator';
import Chart from 'chart.js';
import { Enterprise } from '../../../../entities/enterprise.entity';

@Component
export default class EnterpriseChartAvailable extends Vue {
  public $refs: any;

  @Prop({
    type: Object,
    default: () => new Enterprise(),
  })
  private enterprise!: Enterprise;

  public showDialog: boolean = false;

  private setShowDialog() {
    this.showDialog = true;
  }

  private getTotalValue(data: any[] = []): number {
    const total = data.reduce((acc, it) => {
      acc += it;
      return acc;
    }, 0);
    return total;
  }

  private createChart() {
    const ctx = this.$refs.chart;
    const notAvailableValue = Number(this.enterprise.results.units) - Number(this.enterprise.results.available);
    const data: any = {
      labels: ['Disponíveis', 'Não Disponíveis'],
      datasets: [
        {
          backgroundColor: ['#FF5500', '#dad7e9', '#00bcd4', '#4caf50'],
          data: [this.enterprise.results.available, notAvailableValue],
        },
      ],
    };

    const options = {
      responsive: true,
      cutoutPercentage: 70,
      backgroundColor: '#fff',
    };

    const plugins = [
      {
        beforeDraw: (chart: any) => {
          const { width, height } = chart.chart;
          const context = chart.chart.ctx;

          context.restore();
          const fontSize = '45px';
          context.font = `${fontSize} sans-serif`;
          context.textBaseline = 'middle';

          // const text = ['teste'];
          // const textX = Math.round((width - context.measureText(text).width) / 2);
          // const textY = height / 1.7;
          // context.fillText(text, textX, textY);
          context.save();
        },
      },
    ];

    const myLineChart = new Chart(ctx, {
      type: 'doughnut',
      data,
      options,
      plugins,
    });
  }

  private mounted() {
    this.createChart();
  }
}
