
import { Component, Vue, Prop } from 'vue-property-decorator';
import WinnerRegisterClientsByUnitChart from '@/modules/Dashboard/dashs/winner-register-clients-by-unit.chart.vue';
import SoldWinner from '@/modules/Dashboard/dashs/sold-winner.chart.vue';
import WinnerDashboard from '@/modules/Dashboard/entities/ranking/winner-dashboard.entity';
import IWinnerDashboard from '@/modules/Dashboard/interfaces/winner-dashboard.interface';
import EnterpriseChartAvailable from '../enterprise-chart-available/enterprise-chart-available.chart.vue';
import EnterpriseChartConversion from '../enterprise-chart-conversion/enterprise-chart-conversion.chart.vue';
import { Enterprise } from '../../../../entities/enterprise.entity';

@Component({
  components: {
    EnterpriseChartAvailable,
    EnterpriseChartConversion,
    WinnerRegisterClientsByUnitChart,
    SoldWinner,
  },
})
export default class EnterpriseResume extends Vue {
  @Prop({
    type: Object,
    default: () => new Enterprise(),
  })
  private enterprise!: Enterprise;

  private get winnerRegisterClients() {
    return new WinnerDashboard({
      user_name: this.enterprise.ranking_users.count.user_name,
      value: this.enterprise.ranking_users.count.value,
    });
  }

  private get winnerSold() {
    return new WinnerDashboard({
      user_name: this.enterprise.ranking_users.currency.user_name,
      value: this.enterprise.ranking_users.currency.valueFormatted,
    });
  }

  private get content() {
    return [
      { description: 'Blocos', value: this.enterprise.results.blocks },
      { description: 'Unidades', value: this.enterprise.results.units },
      { description: 'Bloqueado(s)', value: this.enterprise.results.blocked },
      { description: 'Vendas', value: this.enterprise.results.sold },
      { description: 'Reservadas', value: this.enterprise.results.reserved },
      { description: 'Distratadas', value: this.enterprise.results.distract },
      { description: 'Disponíveis', value: this.enterprise.results.available },
      { description: 'VGV Vendido', value: this.enterprise.results.vgvSold },
      { description: 'VGV Total', value: this.enterprise.results.vgvTotal },
    ];
  }
}
