import http from '@/services/http.service';
import { AxiosResponse } from 'axios';

class EnterpriseReadjustUnitValuesService {
  readjust(enterprise_id: string, payload: any): Promise<string[]> {
    return http
      .put(`/enterprise/readjust-unit-value/${enterprise_id}`, payload)
      .then(({ data }: AxiosResponse) => data.data);
  }
}

export default new EnterpriseReadjustUnitValuesService();
